import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/personal-site/personal-site/src/components/Article.js";
import Repl from "components/Repl";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "use-case",
      "style": {
        "position": "relative"
      }
    }}>{`Use Case`}<a parentName="h2" {...{
        "href": "#use-case",
        "aria-label": "use case permalink",
        "className": "header-link after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`You have a JavaScript object that you got from somewhere (e.g. retrieved from
API):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-js line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` restaurant `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Nong's Khao Man Gai"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  address`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"609 SE Ankeny St"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  phone`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"503-740-2907"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  secretKeyLocation`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"somewhere"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`You want to remove `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`secretKeyLocation`}</code>{` before passing the object onto the next
part of the workflow.`}</p>
    <h2 {...{
      "id": "solution-destructurerest-syntax",
      "style": {
        "position": "relative"
      }
    }}>{`Solution: Destructure/Rest Syntax`}<a parentName="h2" {...{
        "href": "#solution-destructurerest-syntax",
        "aria-label": "solution destructurerest syntax permalink",
        "className": "header-link after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`The combination of `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Destructuring_assignment#Object_destructuring"
      }}>{`object destructuring`}</a>{` and `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_syntax#Spread_in_object_literals"
      }}>{`object literal
spread/rest syntax`}</a>{` to the rescue ⛑.`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-js line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` secretKeyLocation`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`...`}</span>{`rest `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` restaurant`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "token function"
          }}>{`doSomethingWith`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`rest`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`With this, you now have three variables:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><code parentName="p" {...{
            "className": "language-text"
          }}>{`secretKeyLocation`}</code>{` with the value of `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`"somewhere"`}</code></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><code parentName="p" {...{
            "className": "language-text"
          }}>{`rest`}</code>{`, a new object with the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`secretKeyLocation`}</code>{` property removed:`}</p>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "js"
        }}><pre parentName="div" {...{
            "style": {
              "counterReset": "linenumber NaN"
            },
            "className": "language-js line-numbers"
          }}><code parentName="pre" {...{
              "className": "language-js"
            }}><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
  name`}<span parentName="code" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="code" {...{
                "className": "token string"
              }}>{`"Nong's Khao Man Gai"`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
  address`}<span parentName="code" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="code" {...{
                "className": "token string"
              }}>{`"609 SE Ankeny St"`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
  phone`}<span parentName="code" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="code" {...{
                "className": "token string"
              }}>{`"503-740-2907"`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`}`}</span></code><span parentName="pre" {...{
              "aria-hidden": "true",
              "className": "line-numbers-rows",
              "style": {
                "whiteSpace": "normal",
                "width": "auto",
                "left": "0"
              }
            }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
        <p parentName="li">{`It’s worth noting that `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`rest`}</code>{` can be whatever variable name you want, there’s
nothing special about the name `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`rest`}</code>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><code parentName="p" {...{
            "className": "language-text"
          }}>{`restaurant`}</code>{` remains untouched and retains its original value. Immutability
FTW 🙌!`}</p>
      </li>
    </ul>
    <Repl repl="JS-object-properties" title="remove JavaScript object properties" mdxType="Repl" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      